import clsx from 'clsx';
import underlineStyles from './underline-bullets.module.css';
import columnStyles from './column-bullets.module.css';
import React, { FC } from 'react';

interface IList {
  listName?: string;
  list: { title: string | React.ReactFragment; text: string | string[] }[];
  textStyle?: string;
  titleStyle?: string;
  listStyle?: string;
  styles: CSSModule;
}

const Bullets = ({ styles, list, textStyle, listStyle, titleStyle }: IList) => {
  return (
    <ul className={clsx(styles.list, listStyle)}>
      {list.map(({ title, text }) => (
        <li className={styles.item} key={title.toString()}>
          <p className={clsx(styles.itemTitle, titleStyle)}>{title}</p>
          {typeof text === 'string' ? (
            <p className={clsx(styles.itemText, textStyle)}>{text}</p>
          ) : (
            <ul>
              {text.map((item: string) => (
                <li className={clsx(styles.listedBullet, textStyle)}>{item}</li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

const makeBullets = <P extends IList>(Component: React.ComponentType<P>) => {
  return (props: IList) => {
    return <Component {...(props as P)} styles={props.styles} />;
  };
};

const WrappedBullets = makeBullets(Bullets);

export const UnderlineBullets = (props: Omit<IList, 'styles'>) => (
  <WrappedBullets styles={underlineStyles} {...props} />
);
export const ColumnList = (props: Omit<IList, 'styles'>) => (
  <WrappedBullets styles={columnStyles} {...props} />
);
