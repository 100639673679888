import clsx from 'clsx';
import styles from './bulleted-list.module.css';
import React from 'react';

interface IList {
  listName?: string;
  list: string[] | { item: string }[];
  listBoxStyle?: string;
  listStyle?: string;
  itemStyle?: string;
  listNameStyle?: string;
}

const BulletedList = ({
  list,
  listName,
  listBoxStyle,
  listStyle,
  itemStyle,
  listNameStyle,
}: IList) => {
  return (
    <div className={clsx(styles.container, listBoxStyle)}>
      {!!listName && <p className={clsx(styles.title, listNameStyle)}>{listName}</p>}
      <ul className={clsx(styles.list, listStyle)}>
        {list.map((item, ind) => (
          <li className={itemStyle ?? styles.item} key={ind}>
            {typeof item === 'string' ? item : item?.item}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default BulletedList;
