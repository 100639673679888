import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import loadable from '@loadable/component';
//styles
import clsx from 'clsx';
import styles from './edtech.module.css';
//components
import Layout from '../components/layout';
import Blockquote from '../../services/components/blockquote';
import { Heypractice2, Math } from 'components/carousel';
import Bullets from 'components/bullets';
import BulletedList from 'components/bullets/bulleted-list';
import { UnderlineBullets } from 'components/bullets/titled-bullets';
import Booking from 'components/feedback';
//assets
import lms from './lms.json';
import selfLearning from './self-learning-app.json';
import workflow from './workflow-automation.json';
import values from './values.json';
import enhances from './enhances.json';
import wellEquippedTeam from './well-equipped-team.json';
//other
import { meta } from '../../../metaData';
import identificators from 'components/googleAnalyticsIdentificators';
interface Props {
  location: Location;
}

const SwiperSlider = loadable(() => import('components/swiper-slider'));
const ReviewSlider = loadable(() => import('components/swiper-slider/review-slider'));

const Edtech = ({ location }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      shradRao: file(relativePath: { eq: "shrad-rao.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      target: file(relativePath: { eq: "industries/target1.svg" }) {
        publicURL
      }
      targetMob: file(relativePath: { eq: "industries/target1-mob.svg" }) {
        publicURL
      }
      davidNeuendorf: file(relativePath: { eq: "david-neuendorf.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heyPractice: file(relativePath: { eq: "hey-practice.svg" }) {
        publicURL
      }
      patrickKedziora: file(relativePath: { eq: "kedziora.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      boilingiceLogo: file(relativePath: { eq: "boilingice-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      looking: file(relativePath: { eq: "industries/looking.svg" }) {
        publicURL
      }
      lookingMob: file(relativePath: { eq: "industries/looking-mob.svg" }) {
        publicURL
      }
      standarts: file(relativePath: { eq: "industries/standarts.svg" }) {
        publicURL
      }
      standartsMob: file(relativePath: { eq: "industries/standarts-mob.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Layout
      title="e-Learning Software Development Services"
      subtitle="Hire a team of expert developers who know how to create edtech solutions."
      linkText="Start your project"
      metaData={{ main: meta.edtech }}
      pathname={location.pathname}
      linkId={identificators.CONTACT_US_HEADER}
    >
      <section className="section">
        <div className="inner">
          <p className={styles.preamble}>
            At Brocoders, you get to work with
            <span className={styles.bold}> e-learning software developers</span> who have the
            mindset of a startup and the vision of a long-term partner. If you're creating an{' '}
            <span className={styles.bold}>edtech product</span>, you won't find a better team than
            Brocoders to develop it.
          </p>
        </div>
      </section>
      <section className={clsx('section', styles.grey, styles.greyWave)}>
        <div className={clsx('inner-container', styles.bulletsContainer)}>
          <p className={styles.title}>Build e-learning software that benefits customers</p>
          <p className={styles.lightParagraph}>
            From LMS to workflow automation to self-learning apps – we've helped develop different
            types of e-learning software and know how to make your solution truly stand out.
          </p>
          <div className={styles.bulletsLists}>
            <BulletedList
              listName="Learning management systems (LMS)"
              list={lms}
              listBoxStyle={styles.bulletsListContainer}
              listNameStyle={styles.bulletsTitle}
            />
            <BulletedList
              listName="Workflow automation for schools and universities"
              list={workflow}
              listBoxStyle={styles.bulletsListContainer}
              listNameStyle={styles.bulletsTitle}
            />
            <BulletedList
              listName="Self-learning applications"
              list={selfLearning}
              listBoxStyle={styles.bulletsListContainer}
              listNameStyle={styles.bulletsTitle}
            />
          </div>
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.benefitsContainer)}>
          <p className={styles.title}>How we add value to your e-learning app development</p>
          <p className={styles.lightParagraph}>
            We provide highly-skilled developers in the shortest amount of time to augment your
            team,  support your existing product, or build new edtech software from scratch.
          </p>
          <BulletedList
            list={values}
            listBoxStyle={styles.valueList}
            listStyle={styles.values}
            itemStyle={styles.valueItem}
          />
        </div>
      </section>
      <section className={clsx('section', styles.target)}>
        <div className={clsx('inner-container', styles.targetContainer)}>
          <picture>
            <source
              srcSet={data.targetMob.publicURL}
              media="(max-width: 768px)"
              type="image/svg+xml"
            />
            <source
              srcSet={data.target.publicURL}
              media="(min-width: 769px)"
              type="image/svg+xml"
            />
            <img src={data.target.publicURL} className={styles.targetImg} alt="" />
          </picture>
          <div className={styles.targetBox}>
            <p className={styles.targetText}>
              Tell us about your product and current challenges and we’ll get back to you with our
              next steps.
            </p>
            <Link
              id={identificators.GET_STARTED}
              className={clsx('btn btn_60 btn__black', styles.link)}
              to="/get-in-touch/"
            >
              Get started
            </Link>
          </div>
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.reviewContainer)}>
          <p className={styles.title}>What our clients think</p>
          <p className={styles.lightParagraph}>
            We're proud to have contributed to
            <span className={styles.bold}> e-learning software development</span> for some amazing
            startups.
          </p>
        </div>
      </section>
      <section className="section">
        <ReviewSlider swiperClassName={styles.blockquoteSwiper}>
          <Blockquote
            text="“With Brocoders, we were able to set up a highly skilled IT team in the shortest amount of time. For finding a team with similar skills we would have needed 4-6 months instead of the instant start.”"
            img={data.davidNeuendorf.childImageSharp.fluid}
            logoImg={data.heyPractice.publicURL}
            isLogo={true}
            logoStyles={styles.HeyPracticeLogo}
            mainIdea="Highly skilled IT team in the shortest amount of time"
            isRating
            author={
              <React.Fragment>
                <strong>David Neuendorf </strong> <br />
                CEO,{' '}
                <a
                  href="https://www.heypractice.com/de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  HeyPractice.com
                </a>
              </React.Fragment>
            }
          />
          <Blockquote
            text="“We have worked on several projects with Brocoders, they have been goal oriented, wanting to think along and willing to learn/experiment with everything.”"
            isRating
            mainIdea="They have been goal oriented"
            author={
              <React.Fragment>
                <strong>Executive, Ed-Tech SaaS Company</strong>
              </React.Fragment>
            }
          />
          <Blockquote
            text="“They put together a team of programmers and worked closely with us and our team. They didn't just do what we told them but make important suggestions and recommendations. In short, they were the perfect development partner. And, they did this on budget and on time.”"
            img={data.patrickKedziora.childImageSharp.fluid}
            logoImg={data.boilingiceLogo.childImageSharp.fluid.srcWebp}
            isLogo={true}
            mainIdea="They were the perfect development partner"
            logoStyles={styles.HeyPracticeLogo}
            isRating
            author={
              <React.Fragment>
                <strong>Patrick Kedziora </strong> <br />
                Founder & CEO,{' '}
                <a
                  href="http://boilingice.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  Boilingice
                </a>
              </React.Fragment>
            }
          />
          <Blockquote
            text="“They listen, provide input, and actually care about what we’re doing.”"
            isRating
            mainIdea="They care about what we’re doing"
            author={
              <React.Fragment>
                <strong>Founder & CEO, Education Platform</strong>
              </React.Fragment>
            }
          />
        </ReviewSlider>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.enhanceContainer)}>
          <p className={styles.title}>
            Enhance your product with innovative digital learning solutions
          </p>
          <p className={styles.lightParagraph}>
            We care about your project as much as you do and we want to see it thrive. That's why
            we're always exploring the latest digital learning tools and technologies that can
            enhance your product and make it more engaging, interactive, and effective for your
            learners.
          </p>
          <UnderlineBullets list={enhances} />
        </div>
      </section>
      <section className={clsx('section', styles.looking)}>
        <div className={clsx('inner-container', styles.lookingContainer)}>
          <picture>
            <source
              srcSet={data.lookingMob.publicURL}
              media="(max-width: 768px)"
              type="image/svg+xml"
            />
            <source
              srcSet={data.looking.publicURL}
              media="(min-width: 769px)"
              type="image/svg+xml"
            />
            <img src={data.target.publicURL} className={styles.lookingImg} alt="" />
          </picture>
          <Link
            id={identificators.TALK_TO_OUR_TEAM}
            className={clsx('btn btn_60 btn__black', styles.link, styles.lookingLink)}
            to="/get-in-touch/"
          >
            Talk to our team
          </Link>
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.responseContainer)}>
          <p className={styles.title}>Our recent work</p>
          <p className={styles.lightParagraph}>
            Whether it's modernizing your e-learning application, adding AI, or gamification — we've
            got the technical expertise and capabilities to handle it all.
          </p>
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider>
          <Heypractice2 />
          <Math />
        </SwiperSlider>
      </section>
      <section className={clsx('section', styles.grey)}>
        <div className={clsx('inner-container', styles.container)}>
          <p className={styles.title}>Support required e-learning standards</p>
          <p className={styles.lightParagraph}>
            As an<span className={styles.bold}> e-learning software development company</span>, we
            have the know-how to help you implement the needed e-learning standards.
          </p>
          <picture>
            <source
              srcSet={data.standartsMob.publicURL}
              media="(max-width: 768px)"
              type="image/svg+xml"
            />
            <source
              srcSet={data.standarts.publicURL}
              media="(min-width: 769px)"
              type="image/svg+xml"
            />
            <img src={data.standarts.publicURL} className={styles.standartsImg} alt="" />
          </picture>
        </div>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.containerBox)}>
          <p className={styles.title}>Build your e-learning app with a well-equipped team</p>
          <p className={styles.lightParagraph}>
            Speed up your project with our fast team set-up and bring experienced
            <span className={styles.bold}> LMS software developers</span>. With a proven track
            record in creating successful online learning platforms, we have the expertise to
            deliver outstanding results. Plus, we have a startup mentality driven by our CTO, who
            co-founded an interactive case study platform.
          </p>
          <Bullets
            list={wellEquippedTeam}
            className={styles.bullets}
            itemStyle={styles.equippedTeamList}
          />
          <Link
            id={identificators.START_PROJECT}
            className={clsx('btn btn_60 btn__red', styles.link, styles.equippedTeamLink)}
            to="/get-in-touch/"
          >
            Get in touch
          </Link>
        </div>
      </section>
      <Booking />
    </Layout>
  );
};

export default Edtech;
